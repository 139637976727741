<template>
  <div
    data-cy="page-fieldworks"
    aria-labelledby="fieldworks page"
    ref="fieldworksPage"
  >
    <div class="header-text pb-3" data-cy="header-fieldworks">Fieldwork</div>
    <div class="subhead-text pb-5">
      Manage your fieldwork, submit new entries and track your progress.
    </div>
    <div class="grid md:grid-cols-3 md:grid-rows-1 gap-8 pb-5">
      <div class="md:col-span-2 box-style">
        <div class="secondhead-text">Tracking your time</div>
        <div class="text-xl pb-7">
          Entries must be submitted in a timely manner.
        </div>
        <div class="py-4">
          <HelpChips :chips="fieldwork_chips" />
        </div>
      </div>
      <div class="box-style md:col-span-1">
        <YourProgress :progress_items="progress_items" />
      </div>
    </div>
    <div class="grid md:grid-cols-3 md:grid-rows-1 gap-8 pb-5">
      <div class="box-style md:col-span-2 md:row-span-2">
        <MyFieldworks class="" :show_all="true" height="" />
      </div>
      <div class="hidden md:block box-style md:col-span-1 md:col-start-3">
        <HereToHelp class="h-full" />
      </div>
    </div>
  </div>
</template>

<script>
import MyFieldworks from "@/components/displays/student-displays/fieldworks-logs/MyFieldworks";
import HereToHelp from "@/components/displays/student-displays/help/HereToHelp";
import YourProgress from "@/components/displays/student-displays/progress/YourProgress";
import HelpChips from "@/components/displays/student-displays/help/HelpChips";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Fieldworks",
  components: { HelpChips, YourProgress, HereToHelp, MyFieldworks },
  mounted() {
    this.$refs.fieldworksPage.focus();
  },
  data() {
    return {
      fieldworks: this.$store.state.fieldworks,
      fieldwork_chips: [
        {
          icon: "user-circle",
          title: "Add Entries",
          description: "Enter your time immediately following your activities.",
          route: "#",
        },
        {
          icon: "check-circle",
          title: "Check Status",
          description: "Make sure your entries are approved before deadlines.",
          route: "#",
        },
        {
          icon: "compass",
          title: "Don't delay",
          description:
            "Events not logged within 90 days may not be approved for credit.",
          route: "#",
        },
      ],
      progress_items: this.$store.state.fieldwork_form_store.progress_items,
      loading: false,
    };
  },
  created() {
    this.get_fieldworks();
    this.$store.dispatch("getStudentProgress", this.acad_level.term.term_id).then(()=> {console.log("getStudentProgress")})
  },
  watch: {
    acad_level() {
      this.get_fieldworks();
    },
  },
  methods: {
    navigate(route) {
      this.$router.push(route);
    },
    get_fieldworks() {
      this.loading = true;
      this.getFieldworkEntries().finally(() => (this.loading = false));
    },
    ...mapActions(["getFieldworkEntries"]),
  },
  computed: {
    ...mapGetters({ acad_level: "getAcadLevel" }),
  },
};
</script>

<style scoped></style>
